<template>
    <div :class="{ active: navbarActive }" id="main-navigation">
        <a href="javascript:void(0)" class="navbar-close" @click="closeNav">
            <BootstrapIcon icon="x-lg" size="1x"/>
        </a>
        <router-link to="/" class="logo">
            <img src="@/assets/images/logo.png" alt="logo"/>
        </router-link>

        <div class="navigation-container">
            <!-- Main navigation links -->
            <nav id="navigation-links">
                <router-link to="/" @click="closeNav">
                    <BootstrapIcon icon="house-door" size="1x"/>
                    {{ $t("navigations.dashboard") }}
                </router-link>
                <router-link v-if="$helper.userCapability('list','documents')" to="/documents" @click="closeNav">
                    <BootstrapIcon icon="file-earmark" size="1x"/>
                    {{ $t("navigations.documents") }}
                </router-link>
                <router-link v-if="$helper.userCapability('list','emails')" to="/emails" @click="closeNav">
                    <BootstrapIcon icon="envelope" size="1x"/>
                    {{ $t("navigations.emails") }}
                </router-link>
                <div class="group">
                    <a href="javascript:void(0)" @click="showGroup('tracking')">
                        <BootstrapIcon icon="stopwatch" size="1x"/>
                        {{ $t("navigations.timeTracking") }}
                    </a>
                    <div :class="groupActive.tracking ? 'group__sub active' : 'group__sub'">
                        <router-link v-if="$helper.userCapability('list','time_tracking')" to="/time-tracking">
                            <BootstrapIcon icon="stopwatch" size="1x"/>
                            {{ $t("navigations.timeTracker") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','projects')" to="/projects">
                            <BootstrapIcon icon="briefcase" size="1x"/>
                            {{ $t("navigations.projects") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','statistics')" to="/statistics">
                            <BootstrapIcon icon="graph-up" size="1x"/>
                            {{ $t("navigations.statistics") }}
                        </router-link>
                    </div>
                </div>
                <router-link v-if="$helper.userCapability('list','read_and_understand')" to="/read-and-understand"
                             @click="closeNav">
                    <BootstrapIcon icon="book" size="1x"/>
                    {{ $t("navigations.read") }}
                </router-link>
                <div class="group">
                    <a href="javascript:void(0)" @click="showGroup('stocks')">
                        <BootstrapIcon icon="bag" size="1x"/>
                        {{ $t("navigations.stocks") }}
                    </a>
                    <div :class="groupActive.stocks ? 'group__sub active' : 'group__sub'">
                        <router-link v-if="$helper.userCapability('list','stock_items')" to="/stock-items">
                            <BootstrapIcon icon="bag" size="1x"/>
                            {{ $t("navigations.stockItems") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','used_items')" to="/used-items">
                            <BootstrapIcon icon="box" size="1x"/>
                            {{ $t("navigations.usedItems") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','deliveries')" to="/deliveries">
                            <BootstrapIcon icon="truck" size="1x"/>
                            {{ $t("navigations.deliveries") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','tool_requests')" to="/tool-requests">
                            <BootstrapIcon icon="wrench" size="1x"/>
                            {{ $t("navigations.toolRequests") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','stock_item_locations')"
                                     to="/storage-locations">
                            <BootstrapIcon icon="geo-alt-fill" size="1x"/>
                            {{ $t("navigations.stockItemLocations") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','stock_item_owners')" to="/stock-item-owners">
                            <BootstrapIcon icon="person" size="1x"/>
                            {{ $t("navigations.stockItemOwners") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','stock_item_types')" to="/stock-item-types">
                            <BootstrapIcon icon="layers" size="1x"/>
                            {{ $t("navigations.stockItemTypes") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','stock_analytics')" to="/stock-analytics">
                            <BootstrapIcon icon="graph-up" size="1x"/>
                            {{ $t("navigations.analytics") }}
                        </router-link>
                    </div>
                </div>
                <div class="group">
                    <a href="javascript:void(0)" @click="showGroup('forms')">
                        <BootstrapIcon icon="ui-checks" size="1x"/>
                        {{ $t("navigations.forms") }}
                    </a>
                    <div :class="groupActive.forms ? 'group__sub active' : 'group__sub'">
                        <router-link v-if="$helper.userCapability('list','forms')" to="/forms">
                            <BootstrapIcon icon="ui-checks" size="1x"/>
                            {{ $t("navigations.mainForms") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','ocr')" to="/ocr">
                            <BootstrapIcon icon="telephone" size="1x"/>
                            {{ $t("navigations.ocr") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','cwo')" to="/cwo">
                            <BootstrapIcon icon="wrench" size="1x"/>
                            {{ $t("navigations.cwo") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','part_requests')" to="/part-requests">
                            <BootstrapIcon icon="tools" size="1x"/>
                            {{ $t("navigations.partRequests") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','form_types')" to="/settings/form-types">
                            <BootstrapIcon icon="ui-checks-grid" size="1x"/>
                            {{ $t("navigations.formTypes") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','ac_types')" to="/settings/ac-types">
                            <BootstrapIcon icon="send" size="1x"/>
                            {{ $t("navigations.acTypes") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','service_requests')" to="/settings/sr-types">
                            <BootstrapIcon icon="gear" size="1x"/>
                            {{ $t("navigations.srTypes") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','csr_stamps')" to="/settings/csr-stamps">
                            <BootstrapIcon icon="file-post" size="1x"/>
                            {{ $t("navigations.csrStamps") }}
                        </router-link>
                    </div>
                </div>
                <router-link v-if="$helper.userCapability('list','receipts')" to="/receipts" @click="closeNav">
                    <BootstrapIcon icon="file-earmark-text" size="1x"/>
                    {{ $t("navigations.receipts") }}
                </router-link>
                <div class="group">
                    <a href="javascript:void(0)" @click="showGroup('invoices')">
                        <BootstrapIcon icon="file-earmark-medical" size="1x"/>
                        {{ $t("navigations.invoices") }}
                    </a>
                    <div :class="groupActive.invoices ? 'group__sub active' : 'group__sub'">
                        <router-link v-if="$helper.userCapability('list','outgoing_invoices')" to="/invoices/outgoing">
                            <BootstrapIcon icon="file-arrow-up" size="1x"/>
                            {{ $t("navigations.outgoing") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','incoming_invoices')" to="/invoices/incoming">
                            <BootstrapIcon icon="file-arrow-down" size="1x"/>
                            {{ $t("navigations.incoming") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','invoice_categories')" to="/invoices/categories">
                            <BootstrapIcon icon="grid-3x2-gap" size="1x"/>
                            {{ $t("navigations.categories") }}
                        </router-link>
                    </div>
                </div>
                <router-link v-if="$helper.userCapability('list','employees')" to="/employees" @click="closeNav">
                    <BootstrapIcon icon="person" size="1x"/>
                    {{ $t("navigations.employees") }}
                </router-link>
                <router-link v-if="$helper.userCapability('list','customers')" to="/customers" @click="closeNav">
                    <BootstrapIcon icon="people" size="1x"/>
                    {{ $t("navigations.customers") }}
                </router-link>
                <div class="group">
                    <a href="javascript:void(0)" @click="showGroup('mpc')">
                        <BootstrapIcon icon="journal" size="1x"/>
                        {{ $t("navigations.mpc") }}
                    </a>
                    <div :class="groupActive.mpc ? 'group__sub active' : 'group__sub'">
                        <router-link v-if="$helper.userCapability('list','holiday_requests')" to="/holiday-requests">
                            <BootstrapIcon icon="journal" size="1x"/>
                            {{ $t("navigations.holiday") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','travelling_orders')" to="/travelling-orders">
                            <BootstrapIcon icon="briefcase" size="1x"/>
                            {{ $t("navigations.travellingOrders") }}
                        </router-link>
                    </div>
                </div>
                <router-link v-if="$helper.userCapability('list','exports')" to="/exports" @click="closeNav">
                    <bootstrap-icon
                        icon="file-arrow-down"
                        size="1x"
                    />
                    {{ $t('navigations.exports') }}
                </router-link>
                <div class="separator"></div>
                <div class="group">
                    <a href="javascript:void(0)" @click="showGroup('settings')">
                        <BootstrapIcon icon="gear" size="1x"/>
                        {{ $t("navigations.settings") }}
                    </a>
                    <div :class="groupActive.settings ? 'group__sub active' : 'group__sub'">
                        <router-link v-if="$helper.userCapability('list','banners')" to="/settings/banners">
                            <BootstrapIcon icon="image" size="1x"/>
                            {{ $t("navigations.banners") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','branch')" to="/settings/branches">
                            <BootstrapIcon icon="building" size="1x"/>
                            {{ $t("navigations.branch") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','department')" to="/settings/departments">
                            <BootstrapIcon icon="house-door" size="1x"/>
                            {{ $t("navigations.department") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','station')" to="/settings/stations">
                            <BootstrapIcon icon="geo-alt-fill" size="1x"/>
                            {{ $t("navigations.station") }}
                        </router-link>
                        <router-link v-if="$helper.userCapability('list','email_templates')" to="/settings/emails">
                            <BootstrapIcon icon="envelope" size="1x"/>
                            {{ $t("navigations.emailTemplates") }}
                        </router-link>
                    </div>
                </div>
                <router-link v-if="$helper.userCapability('list','roles')" to="/roles" @click="closeNav">
                    <BootstrapIcon icon="key" size="1x"/>
                    {{ $t("navigations.roles") }}
                </router-link>
                <router-link to="/logout" @click="closeNav">
                    <BootstrapIcon icon="box-arrow-right" size="1x"/>
                    {{ $t("navigations.logout") }}
                </router-link>

            </nav>
        </div>
        <div class="separator"></div>
        <router-link v-if="user" to="/profile" class="user">
            <b-list-group>
                <b-list-group-item class="user-circle">
                    <span class="b-avatar bg-primary rounded-circle user-photo">
                        <BootstrapIcon v-if="!user.fileUrl" icon="person-fill" size="1x"/>
                        <img v-if="user.fileUrl" :src="user.fileUrl" alt="">
                    </span>
                    <div class="user-info">
                        {{ user.givenName }} {{ user.familyName }}
                        <span>{{ $helper.getEnumTranslation("roles", user.role, $i18n.locale) }}</span>
                    </div>


                </b-list-group-item>
            </b-list-group>
        </router-link>
    </div>
</template>

<script>
import {getUserData} from "@/auth/utils";

export default {
    name: "NavBar",
    props: {
        navbarActive: {type: Boolean, default: false},
    },
    data() {
        return {
            groupActive: {
                'stocks': false,
                'mpc': false,
                'settings': false,
                'forms': false,
                'tracking': false,
                'invoices': false
            },
            user: getUserData()
        }
    },
    methods: {
        closeNav() {
            this.$emit("navToggle", false);
            for (const prop in this.groupActive) {
                // skip loop if the property is from prototype
                this.groupActive[prop] = false
            }
        },
        showGroup(name) {
            this.groupActive[name] = !this.groupActive[name]
        },
        getActiveClass() {
            let className = "";
            if (this.navbarActive === true) {
                className = "active";
            }
            return className;
        },
    },
};
</script>
